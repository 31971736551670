
import { defineComponent ,ref ,reactive, toRefs, onMounted, onBeforeMount,watchEffect} from 'vue';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { getUserInfo , modifyPassword} from '@/api/personal';
import  upload  from '@/components/upload/upload.vue'
import { deepClone } from '@/utils/common';
import { useRouter } from "vue-router";
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
export default defineComponent({
    name:"personalSettings",
    components: {
        LoadingOutlined,
        PlusOutlined,
        upload,
        breadcrumb
    },
    setup(){
        // 面包屑
        const breadData = ref<Array<object>>([
            {title:'首页',path:'#/'},
            {title:'个人',path:'/index'},
            {title:'个人设置',path:'#/'},
        ])
        const router = useRouter();
        const userInfo = ref<any>({})
        const visible = ref<boolean>(false)
        const form = reactive({
            name:'',
            password:'',
            con_password:'',
            avatar:'',
            old_password:''
        })
        const userData = ref<any>({})
        
        onBeforeMount(()=>{
            userInfo.value = localStorage.getItem('userInfo')
            let role_id = JSON.parse(userInfo.value as string)
            if((role_id as any).role_id == 5){
                breadData.value.splice(2,1)
            }
            queryUserInfo()
        })
        const queryUserInfo = ():void=>{
            getUserInfo({id:JSON.parse(userInfo.value).id}).then((res:any)=>{
                if(res.code == 200){
                    localStorage.setItem('avatar',res.data.avatar)
                    userData.value = res.data;
                }
            })
        }

        const modify = ():void =>{
            let data:any = {}
            data = deepClone(form)
            data.id = userData.value.id,
            data.status = userData.value.status
            modifyPassword(data).then((res:any)=>{
                if(res.code == 200){
                    if(res.data.is_jump == 1){
                        visible.value = true;
                    }
                    
                    localStorage.removeItem('password')
                    form.name = '';
                    form.password = '';
                    form.con_password = '';
                    form.avatar = '';
                    if(!data.password && !data.password){
                        queryUserInfo()
                    }
                    message.success('修改成功')
                }else{
                    message.error(res.msg)
                }
            })
        }

        const success = (val:any):void =>{
            form.avatar = val[0].path_file;
        }

        const handleOk = ():void =>{
            router.push({name:'login'})
        }

        return {
            ...toRefs(form),
            userData,
            modify,
            success,
            visible,
            handleOk,
            breadData
        }
    }
})
